import { Route, Routes } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
import NotFound from "./components/NotFound";
import AppLayout from "./layouts/AppLayout";
import routes from "./router/routes";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<AppLayout />}>
          {routes.map((route, idx) => {
            return (
              route.component && (
                <Route
                  key={idx}
                  exact={route.exact}
                  path={route.path}
                  name={route.name}
                  element={<route.component />}
                />
              )
            );
          })}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
