import {
  quoteActions,
  policyHolderActions,
  protectedAssetActions,
  customDataActions,
  insuranceProductActions,
  distributionDetailsActions,
  UIactions,
  userActions,
} from "../actions/index";
import {
  Row,
  Col,
  Container,
  Nav,
  Card,
  Form,
  Badge,
  Button,
  Accordion,
} from "react-bootstrap";
import {
  setItemLocalStorage,
  getItemLocalStorage,
  clearLocalStorage,
  setDistributionDetails,
} from "../utils/helper";
import { SECONDARY_COLOR, MAIN_COLOR } from "../config";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, Fragment, useRef } from "react";
import { ToastContainer } from "react-toastify";
import Lifeline from "../assets/images/Lifeline.png";
import { ThreeDots } from "react-loader-spinner";
import BoxImg from "../assets/images/Vector.png";
import Block from "../assets/images/block.png";
import Pause from "../assets/images/Pause.png";
import Plus from "../assets/images/Plus.png";
import { useDispatch, useSelector } from "react-redux";
import { LIGHT_BLUE } from "../color";
import {
  getAvailableFrequencies,
  getCurrentOrLastFrequency,
  getRateBaseForAmountAndFrequency,
  isNullOrUndefined,
} from "./forms/utils";
import PriceSlider from "./forms/shared/PriceSlider";
import { treatPropertiesArrayFromResponse } from "../utils/helper";
import { LOCAL_STORAGE_KEYS as LOCAL_STORAGE } from "../utils/constants";
import {
  authGuests,
  fetchTheme,
  getQuoteSpecs,
  retrieveRateBaseTable,
  submitQuote,
} from "../api/requests";
import baseErrorHandler from "../services/baseErrorHandler";

const UsedBioSafe = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [rateBase, setRatebase] = useState("0");
  const [documents, setDocuments] = useState("");
  const [amountList, setAmountlist] = useState([]);
  const [invoicelist, setInvoicelist] = useState([]);
  const [amountsteps, setAmountSteps] = useState([]);
  const [rateBaseTable, setRateBaseTable] = useState([]);
  const [amount, setAmount] = useState({ single: 0 });
  const [invoice_type, setInvoicetype] = useState([]);
  const [globalDisclaimer, setGlobalDisclaimer] = useState("");
  const [payment_frequency, setPaymentFrequency] = useState("");
  const [productDisclaimer, setProductDisclaimer] = useState("");
  const [paymentfrequencylist, setPaymentFrequencyList] = useState("");
  const [payment_frequency_name, setPaymentFrequencyName] = useState("");

  const [isBlinking, setIsBlinking] = useState(false);
  const toggleBlinking = () => {
    setIsBlinking(true);
    setTimeout(() => setIsBlinking(false), 1000);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const openDropdownRef = useRef(null);

  const handleClickInsideDropdown = () => {
    const wasOpenBeforeClick = isDropdownOpen;
    setIsDropdownOpen(false);
    if (wasOpenBeforeClick)
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
  };

  const handleClickOutsideDropdown = (event) => {
    const wasClickOnDropdown =
      openDropdownRef?.current?.contains(event.target) ?? false;
    if (!wasClickOnDropdown) {
      setIsDropdownOpen(false);
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    }
  };

  if (isDropdownOpen) {
    document.addEventListener("mousedown", handleClickOutsideDropdown);
  } else {
    document.removeEventListener("mousedown", handleClickOutsideDropdown);
  }

  const userType = getItemLocalStorage(LOCAL_STORAGE.TYPE);
  const accessToken = getItemLocalStorage(LOCAL_STORAGE.ACCESS_TOKEN);
  const fingerprint = getItemLocalStorage(LOCAL_STORAGE.FINGERPRINT);

  const distributionDetails = useSelector((state) => state.distributionDetails);
  const UI = useSelector((state) => state.UI);
  const images = UI?.theme?.images;
  const isMobile = window.innerWidth < 768;
  const getBackgroundImage = () => {
    return isMobile
      ? images?.bio_image_mobile ?? ""
      : images?.bio_image_desktop ?? "";
  };
  const customData = useSelector((state) => state.customData ?? {});
  const loaderActions = UI?.loader?.actions ?? [];
  const isLoader = loaderActions.length > 0;

  const handleInsuredAmountChange = (event) => {
    const isRangeInput = event.target.name === "single";

    const newInsuredAmount = isRangeInput
      ? rateBaseTable[event.target.value].total_amount
      : event.target.value;

    const currentOrLastFrequency = getCurrentOrLastFrequency(
      customData.payment_frequency,
      rateBaseTable,
      newInsuredAmount
    );

    const newRateBase = getRateBaseForAmountAndFrequency(
      rateBaseTable,
      newInsuredAmount,
      currentOrLastFrequency.data,
      payment_frequency
    );

    setPaymentFrequency(currentOrLastFrequency.data);
    setAmount(newInsuredAmount);
    setRatebase(newRateBase);
  };

  const authGuestAsync = async () => {
    dispatch(UIactions.startAction("AUTH_GUESTS", {}));
    dispatch(customDataActions.clear({}));
    dispatch(distributionDetailsActions.clear({}));
    dispatch(insuranceProductActions.clear({}));
    dispatch(policyHolderActions.clear({}));
    dispatch(protectedAssetActions.clear({}));
    dispatch(quoteActions.clear({}));
    dispatch(userActions.clear({}));
    const fingerprintResponse = await authGuests();
    if (fingerprintResponse.type === "error") {
      dispatch(UIactions.stopAction("AUTH_GUESTS", {}));
      const { form, dialog } = baseErrorHandler(fingerprintResponse);
      if (dialog?.open ?? false) dispatch(UIactions.openErrorDialog(dialog));
      if (Object.keys(form).length > 0) {
        dispatch(UIactions.addErrorMessageToNamespace(form));
      }
      return;
    }

    setItemLocalStorage(
      LOCAL_STORAGE.FINGERPRINT,
      fingerprintResponse.data.fingerprint
    );
    setItemLocalStorage(LOCAL_STORAGE.TYPE, "guest");
    dispatch(
      distributionDetailsActions.update(setDistributionDetails("guest"))
    );
    dispatch(UIactions.stopAction("AUTH_GUESTS", {}));
    await getQuoteSpecsAsync(fingerprintResponse.data.fingerprint);
  };

  useEffect(() => {
    dispatch(UIactions.setUiInitialState());
    setThemeAsync();
    if (state?.component !== "Login") {
      clearLocalStorage();
      authGuestAsync();
      return;
    }

    getQuoteSpecsAsync(fingerprint);
  }, [state]);

  const setThemeAsync = async () => {
    dispatch(UIactions.startAction("SETTING_THEME", {}));
    try {
      const { data: theme, type: status } = await fetchTheme();
      dispatch(UIactions.setTheme(theme));

      if (status === "success") {
        dispatch(UIactions.setTheme(theme));
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(UIactions.stopAction("SETTING_THEME", {}));
    }
  };

  const getRateBaseRequest = async (fingerprint) => {
    dispatch(UIactions.startAction("RETRIEVE_RATE_BASE_TABLE", {}));
    const response = await retrieveRateBaseTable(
      fingerprint,
      userType ?? "",
      accessToken ?? ""
    );
    if (response.type === "error") {
      dispatch(UIactions.stopAction("RETRIEVE_RATE_BASE_TABLE", {}));
      const { form, dialog } = baseErrorHandler(response);
      if (dialog?.open ?? false) dispatch(UIactions.openErrorDialog(dialog));
      if (Object.keys(form).length > 0) {
        dispatch(UIactions.addErrorMessageToNamespace(form));
      }
      return;
    }

    const { invoice_type, ...customData } = response?.data?.custom_data;
    const invoiceTypeProperty = {
      namespace: "invoice_type",
      options: invoice_type ?? [],
    };
    if (invoice_type)
      dispatch(
        protectedAssetActions.update({ properties: [invoiceTypeProperty] })
      );
    setInvoicelist(invoiceTypeProperty.options);

    dispatch(
      quoteActions.update({
        properties: [
          {
            namespace: "payment_frequency",
            options: customData?.payment_frequency ?? [],
          },
        ],
      })
    );
    dispatch(customDataActions.update(customData));

    const fetchedPaymentFrequencies = customData?.payment_frequency ?? [];

    const fetchedAmountList = customData?.total_amount ?? [];
    const initialAmount = fetchedAmountList[0]?.data;

    const fetchedRateBaseTable = customData?.rate_base_table ?? [];
    const initialFrequency = fetchedPaymentFrequencies?.find(
      (firstAvailableFrequency) => {
        const chosenRateBaseRef = fetchedRateBaseTable.find(
          (rateBaseRef) => rateBaseRef.total_amount === initialAmount
        );
        const isFrequencyAvailable = Boolean(
          chosenRateBaseRef?.[firstAvailableFrequency.data]
        );
        return isFrequencyAvailable;
      }
    )?.data;

    const initialRateBase = getRateBaseForAmountAndFrequency(
      fetchedRateBaseTable,
      initialAmount,
      initialFrequency
    );

    setAmountlist(fetchedAmountList);
    setPaymentFrequencyList(fetchedPaymentFrequencies);
    setRateBaseTable(fetchedRateBaseTable);

    setPaymentFrequency(initialFrequency);
    setAmount(initialAmount);
    setRatebase(initialRateBase);
    dispatch(UIactions.stopAction("RETRIEVE_RATE_BASE_TABLE", {}));
  };

  const getQuoteSpecsAsync = async (fingerprint) => {
    dispatch(UIactions.startAction("GET_QUOTE_SPECS", {}));
    const response = await getQuoteSpecs(
      fingerprint,
      userType ?? "",
      accessToken ?? ""
    );

    if (response.type === "error") {
      dispatch(UIactions.stopAction("GET_QUOTE_SPECS", {}));
      const { form, dialog } = baseErrorHandler(response);
      if (dialog?.open ?? false) dispatch(UIactions.openErrorDialog(dialog));
      if (Object.keys(form).length > 0) {
        dispatch(UIactions.addErrorMessageToNamespace(form));
      }
      return;
    }
    const quotePropertiesUpdate = treatPropertiesArrayFromResponse(
      response?.data?.quote_specs?.specs?.quoteproperty_specs
    );
    const policyHolderPropertiesUpdate = treatPropertiesArrayFromResponse(
      response?.data?.quote_specs?.specs?.policyholderproperty_specs
    );
    const protectedAssetPropertiesUpdate = treatPropertiesArrayFromResponse(
      response?.data?.quote_specs?.specs?.protectedassetproperty_specs
    );
    const insuranceProductUpdate = response?.data?.insurance_product;

    if (
      isNullOrUndefined(quotePropertiesUpdate) ||
      isNullOrUndefined(policyHolderPropertiesUpdate) ||
      isNullOrUndefined(protectedAssetPropertiesUpdate) ||
      isNullOrUndefined(insuranceProductUpdate)
    ) {
      console.error(`Missing values`);
      dispatch(UIactions.stopAction("GET_QUOTE_SPECS", {}));
      return;
    }

    const fetchedInvoiceList = protectedAssetPropertiesUpdate.properties.find(
      (property) => property.namespace === "invoice_type"
    );
    if (!isNullOrUndefined(fetchedInvoiceList))
      setInvoicelist(fetchedInvoiceList.options);

    const fetchedGlobalDisclaimer =
      insuranceProductUpdate.legal_disclaimers.find(
        (disclaimer) => disclaimer.type === "global"
      );
    const fetchedProductDisclaimer =
      insuranceProductUpdate.legal_disclaimers.find(
        (disclaimer) => disclaimer.type === "by_product"
      );
    const fetchedProductInfoDocument =
      insuranceProductUpdate.documents.at_product_info;

    setGlobalDisclaimer(fetchedGlobalDisclaimer);
    setProductDisclaimer(fetchedProductDisclaimer);
    setDocuments(fetchedProductInfoDocument);

    dispatch(quoteActions.update(quotePropertiesUpdate));
    dispatch(policyHolderActions.update(policyHolderPropertiesUpdate));
    dispatch(protectedAssetActions.update(protectedAssetPropertiesUpdate));
    dispatch(insuranceProductActions.update(insuranceProductUpdate));
    dispatch(UIactions.stopAction("GET_QUOTE_SPECS", {}));

    await getRateBaseRequest(fingerprint);
  };

  const handleSliderChange = (event) => {
    const newValue = event.target.value;
    const newAmount = amountList[newValue];
    const currentFrequency = payment_frequency;

    const currentOrLastFrequency = getCurrentOrLastFrequency(
      customData.payment_frequency,
      customData.rate_base_table,
      newAmount.data,
      currentFrequency
    );
    const newRateBase = getRateBaseForAmountAndFrequency(
      customData.rate_base_table,
      newAmount.data,
      currentOrLastFrequency.data
    );

    setAmount(newAmount.data);
    setRatebase(newRateBase);
    setPaymentFrequency((prevFrequency) => {
      if (currentOrLastFrequency.data !== prevFrequency) toggleBlinking();
      return currentOrLastFrequency.data;
    });
  };

  const handleFrequencyChange = (event) => {
    const newFrequency = event.target.value;
    const newFrequencyLabel = paymentfrequencylist.find(
      (frequency) => frequency.data === newFrequency
    ).label;

    const insuredAmount = amount;
    const newRateBase = getRateBaseForAmountAndFrequency(
      rateBaseTable,
      insuredAmount,
      newFrequency
    );
    if (isNullOrUndefined(newRateBase)) return null;

    setPaymentFrequency(newFrequency);
    setPaymentFrequencyName(newFrequencyLabel);
    setRatebase(newRateBase);
  };

  const handleInvoice = (e) => {
    let updateInvoice = [...invoice_type];
    if (e.target.checked) {
      updateInvoice = [...invoice_type, e.target.value];
    } else {
      updateInvoice.splice(invoice_type.indexOf(e.target.value), 1);
    }
    setInvoicetype(updateInvoice);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(UIactions.startAction("SUBMIT_QUOTE", {}));
    dispatch(UIactions.clearErrorForm());
    const protectedAssetPropertiesUpdate = [
      {
        namespace: "total_amount",
        data: amount,
      },
    ];
    if (invoice_type.length > 0)
      protectedAssetPropertiesUpdate.push({
        namespace: "invoice_type",
        data: invoice_type.toString(),
      });
    const quotePropertiesUpdate = [
      {
        namespace: "distribution_details",
        data: JSON.stringify(distributionDetails),
      },
      {
        namespace: "payment_frequency",
        data: payment_frequency,
      },
    ];

    const formData = {
      protectedasset_properties: protectedAssetPropertiesUpdate,
      quote_properties: quotePropertiesUpdate,
    };

    dispatch(quoteActions.update({ properties: quotePropertiesUpdate }));
    dispatch(
      protectedAssetActions.update({
        properties: protectedAssetPropertiesUpdate,
      })
    );

    const response = await submitQuote(
      fingerprint,
      userType ?? "",
      accessToken ?? "",
      formData
    );
    if (response.type === "error") {
      dispatch(UIactions.stopAction("SUBMIT_QUOTE", {}));
      const { form, dialog } = baseErrorHandler(response);
      if (dialog?.open ?? false) dispatch(UIactions.openErrorDialog(dialog));
      if (Object.keys(form).length > 0) {
        dispatch(UIactions.addErrorMessageToNamespace(form));
      }
      return;
    }

    const quoteUpdates = [
      ...(response.data?.customized?.quote_properties ?? []),
      ...(response.data?.simulated?.quote_properties ?? []),
    ];

    dispatch(
      protectedAssetActions.setID(response.data.created.protected_assets[0].id)
    );
    dispatch(quoteActions.setID(response.data.created.id));
    dispatch(
      quoteActions.update(treatPropertiesArrayFromResponse(quoteUpdates))
    );

    dispatch(UIactions.stopAction("SUBMIT_QUOTE", {}));
    dispatch(UIactions.setStep(1));
    navigate("/simulation");
  };

  const invoiceInsurance = [
    {
      img: BoxImg,
      heading: "Desemprego involuntário",
      text: "Até €3.600 para situações de desemprego involuntário em trabalhadores com contrato sem termo",
      class: "first",
    },
    {
      img: Pause,
      heading: "Incapacidade temporária",
      text: "Até €3.600 para situações de incapacidade temporária para o trabalho superior a 30 dias",
      class: "second",
    },
    {
      img: Plus,
      heading: "Hospitalização",
      text: "Até €600 para situações de hospitalização superior a 3 dias",
      class: "third",
    },
    {
      img: Lifeline,
      heading: "Invalidez permanente",
      text: "Até €600 para situações de morte ou invalidez do titular",
      class: "fourth",
    },
  ];

  return (
    <Fragment>
      <ToastContainer theme="dark" autoClose={10000} />
      {isLoader === true && (
        <ThreeDots
          height="90"
          width="90"
          radius="9"
          color="#FDBE14"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass="ajax-loader"
          visible={true}
        />
      )}
      <Row className="m-0">
        <div
          className="main-banner"
          style={{
            backgroundImage: `url(${getBackgroundImage()})`,
            width: "100%" /* 1920px */,
            aspectRatio: `${isMobile ? "680 / 712" : "1920 / 570"}`,
            minHeight: `${isMobile ? "0" : "350px"}`,
            maxHeight: `${isMobile ? "655px" : "570px"}`,

            backgroundPosition: "center top",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="form-div">
            <Container>
              <Row className="text-start">
                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                  <h3 className="banner-heading">
                    Seguro de Proteção de Pagamentos
                  </h3>
                </Col>
                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                  <Card className="border-0 quick-simulation-card ms-auto">
                    <Form
                      onSubmit={handleSubmit}
                      method="post"
                      className="quick-simulation-form"
                    >
                      <h4 className="simulation-form-heading">
                        Simulação Rápida
                      </h4>
                      {invoicelist.length > 0 && (
                        <Form.Group className="mb-3">
                          <Form.Label className="simulation-form-label">
                            Que faturas queres proteger?
                          </Form.Label>
                          <div className="invoice-type d-flex flex-wrap">
                            {invoicelist &&
                              invoicelist.map((val, index) => {
                                return (
                                  <Badge
                                    key={index}
                                    pill
                                    bg={`${
                                      invoice_type.includes(val.data) === true
                                        ? "primary"
                                        : "white"
                                    }`}
                                    className={`me-2 mb-2 ${
                                      val.checked === true ? "pe-3" : ""
                                    }`}
                                  >
                                    <Form.Check
                                      type="checkbox"
                                      value={val.data}
                                      onChange={handleInvoice}
                                    />
                                    {invoice_type.includes(val.data) ? (
                                      <i className="bi bi-check-lg me-2"></i>
                                    ) : (
                                      ""
                                    )}
                                    {val.label}
                                  </Badge>
                                );
                              })}
                          </div>
                        </Form.Group>
                      )}

                      <Form.Group className="mb-3">
                        <Form.Label className="simulation-form-label">
                          Qual o montante mensal a proteger?
                        </Form.Label>
                        <PriceSlider
                          totalAmountList={amountList}
                          totalAmount={amount}
                          onChange={(e) => handleSliderChange(e)}
                        />
                        <Form.Select
                          name="amount"
                          className="d-flex d-md-none"
                          onChange={(e) => handleInsuredAmountChange(e)}
                        >
                          {amountList &&
                            amountList.map((val, index) => {
                              return (
                                <option key={index} value={val.data}>
                                  {val.label}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </Form.Group>
                      {paymentfrequencylist.length > 1 ? (
                        <Form.Group className="mb-4 d-flex align-items-center position-relative">
                          <Form.Label className="simulation-form-label m-0">
                            Periodicidade
                          </Form.Label>
                          <Form.Select
                            name="payment_frequency"
                            className={`simulation-form-label design-select ${
                              isBlinking ? "blinking" : ""
                            }`}
                            onMouseDown={(e) => {
                              e.preventDefault();
                              setIsDropdownOpen(!isDropdownOpen);
                              e.stopPropagation();
                            }}
                          >
                            <option>
                              {
                                paymentfrequencylist.find(
                                  (chosenPaymentFrequency) =>
                                    chosenPaymentFrequency.data ===
                                    payment_frequency
                                )?.label
                              }
                            </option>
                          </Form.Select>
                          {isDropdownOpen && (
                            <div
                              className="position-absolute top-100 start-0 end-0 design-select-dropdown"
                              ref={openDropdownRef}
                            >
                              {paymentfrequencylist &&
                                getAvailableFrequencies(
                                  paymentfrequencylist,
                                  rateBaseTable,
                                  amount
                                ).map(({ data, label }) => {
                                  return (
                                    <option
                                      className={
                                        payment_frequency === data
                                          ? " selected"
                                          : ""
                                      }
                                      name="payment_frequency"
                                      key={data}
                                      value={data}
                                      onClick={(e) => {
                                        handleFrequencyChange(e);
                                        handleClickInsideDropdown();
                                        setIsDropdownOpen(false);
                                      }}
                                    >
                                      {label}
                                    </option>
                                  );
                                })}
                            </div>
                          )}
                        </Form.Group>
                      ) : (
                        ""
                      )}

                      <Form.Group>
                        <div className="montly_value_div d-flex align-items-center justify-content-between">
                          <Form.Label className="simulation-form-label m-0">
                            Valor
                          </Form.Label>
                          <span className="monthly_value">{rateBase} €</span>
                        </div>
                        {invoicelist.length > 0 ? (
                          invoice_type.length === 0 ||
                          payment_frequency === "" ? (
                            <Button
                              className="simulation-form-button disabled"
                              variant="warning"
                              type="button"
                            >
                              CONTINUAR
                            </Button>
                          ) : (
                            <Button
                              className="simulation-form-button"
                              variant="warning"
                              style={{ background: MAIN_COLOR }}
                              type="submit"
                            >
                              CONTINUAR
                            </Button>
                          )
                        ) : amountsteps[amount.single] === "" ? (
                          <Button
                            className="simulation-form-button disabled"
                            variant="warning"
                            type="button"
                          >
                            CONTINUAR
                          </Button>
                        ) : (
                          <Button
                            className="simulation-form-button"
                            variant="warning"
                            style={{ background: MAIN_COLOR }}
                            type="submit"
                          >
                            CONTINUAR
                          </Button>
                        )}
                      </Form.Group>
                    </Form>
                  </Card>
                </Col>
              </Row>
              <Row>
                {userType !== "lead" && (
                  <>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                      <div
                        className="invoice-box-heading text-start"
                        style={{ color: SECONDARY_COLOR }}
                      >
                        Seguro de Proteção de Pagamentos assegura a totalidade
                        do pagamento total das suas faturas em caso de:
                      </div>
                    </Col>
                    {invoiceInsurance &&
                      invoiceInsurance.map((val, index) => {
                        return (
                          <Col
                            key={index}
                            xxl={3}
                            xl={3}
                            lg={3}
                            md={3}
                            sm={6}
                            xs={12}
                            className="mb-4 mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-0"
                          >
                            <div className="invoice-insurance-box h-100">
                              <div
                                className={`invoice-box-icon invoice-box-icon-${val.class}`}
                              >
                                <img src={val.img} alt="img" />
                              </div>
                              <h5
                                className="invoice-heading"
                                style={{ color: SECONDARY_COLOR }}
                              >
                                {val.heading}
                              </h5>
                              <div className="invoice-text">{val.text}</div>
                            </div>
                          </Col>
                        );
                      })}

                    <Col
                      xxl={12}
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="pt-5"
                    >
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            <img
                              src={Block}
                              alt="img"
                              width="20px"
                              className="me-2"
                            />{" "}
                            Principais exclusões do Seguro de Proteção de
                            Pagamentos
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="d-block mb-3">
                              <div className="acor-heading">DESEMPREGO</div>
                              <div className="acor-text text-start">
                                Situação de reforma; Contrato a termo;
                                Desemprego sazonal.
                              </div>
                            </div>
                            <div className="d-block mb-3">
                              <div className="acor-heading">
                                Incapacidade temporária
                              </div>
                              <div className="acor-text text-start">
                                Gravidez e parto.
                              </div>
                            </div>
                            <div className="d-block mb-3">
                              <div className="acor-heading">
                                Invalidez permanente
                              </div>
                              <div className="acor-text text-start">
                                Doença, tentativa de suicídio ou qualquer ato
                                intencional da pessoa segura.
                              </div>
                            </div>
                            <div className="d-block mb-3">
                              <div className="acor-heading">Hospitalização</div>
                              <div className="acor-text text-start">
                                Hospitalização causada intencionalmente pela
                                pessoa segura, não prescrita por um médico ou
                                para fins estéticos/cosméticos;Acidente ou
                                doença sofridos sobre o efeito de álcool ou
                                drogas.
                              </div>
                            </div>
                            <div className="d-block">
                              <div className="acor-heading">Morte</div>
                              <div className="acor-text text-start">
                                Suicídio; Doença.
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Col>
                  </>
                )}
                {userType !== "lead" && documents && documents.length > 0 && (
                  <Col
                    xxl={12}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="pt-5"
                  >
                    <div className="legal-information text-start">
                      <div className="text">
                        <span style={{ color: "#00133E" }} className="d-block">
                          Informação Legal
                        </span>
                        <span className="d-block">
                          Esta informação não dispensa a consulta da informação
                          pré-contratual e contratual legalmente exigida:
                        </span>
                      </div>
                    </div>
                    <Nav
                      className="flex-column mt-3 text-start"
                      activeKey="/home"
                    >
                      {userType !== "lead" &&
                        documents &&
                        documents.map((item, index) => {
                          return (
                            <Nav.Item key={index}>
                              <Link
                                to={item.uri}
                                target="_blank"
                                className="p-0 nav-link"
                              >
                                <i className="bi bi-dot"></i> {item.name}
                              </Link>
                            </Nav.Item>
                          );
                        })}
                    </Nav>
                  </Col>
                )}
              </Row>
            </Container>
          </div>
        </div>
        <div className="d-block footer-div">
          <Container className="text-start">
            <div className="footer-text">
              {globalDisclaimer ? (
                <p className="m-0">{globalDisclaimer.text}</p>
              ) : (
                ""
              )}
              {productDisclaimer ? (
                <p className="m-0">{productDisclaimer.text}</p>
              ) : (
                ""
              )}
            </div>
          </Container>
        </div>
      </Row>
    </Fragment>
  );
};

export default UsedBioSafe;
