import baseRequestHandler from "../services/baseRequestHandler";
import { VERSION, PROXY_PATH, CDN_BASE_URL } from "../config";
import axios from "axios";

const getHeaders = (fingerprint, userType, accessToken) => {
  const headers = {
    "Content-Type": "application/json",
    "X-FINGERPRINT": `${fingerprint}`,
  };

  const needsAuthorization =
    userType && userType !== "guest" && userType !== "user" && userType !== "";

  if (needsAuthorization) headers["Authorization"] = `Bearer ${accessToken}`;
  return headers;
};

// GET REQUESTS

export const getQuoteSpecs = async (fingerprint, userType, accessToken) => {
  const url = `${PROXY_PATH}/versions/${VERSION}/quote-specs`;
  const payload = {
    headers: getHeaders(fingerprint, userType ?? "", accessToken ?? ""),
  };

  const getQuoteSpecsRequest = baseRequestHandler((params) =>
    axios(url, params)
  );
  return await getQuoteSpecsRequest(payload);
};

export const getQuotes = async (fingerprint, quoteID) => {
  const url = `${PROXY_PATH}/quotes/${quoteID}`;
  const payload = { headers: getHeaders(fingerprint) };

  const getQuotesRequest = baseRequestHandler((params) =>
    axios.get(url, params)
  );
  return await getQuotesRequest(payload);
};

// POST REQUESTS

export const authGuests = async () => {
  const url = `${PROXY_PATH}/auth-guests`;
  const payload = {};

  const fingerprintRequest = baseRequestHandler((payload) =>
    axios.post(url, { payload })
  );
  return await fingerprintRequest(payload);
};

export const authUsers = async (fingerprint) => {
  const url = `${PROXY_PATH}/auth-users`;
  const payload = {
    fingerprint: fingerprint,
  };

  const fingerprintRequest = baseRequestHandler((params) =>
    axios.post(url, params)
  );
  return await fingerprintRequest(payload);
};

export const submitQuote = async (
  fingerprint,
  userType,
  accessToken,
  mainPayload
) => {
  const url = `${PROXY_PATH}/versions/${VERSION}/submit-quote`;
  const payload = {
    params: { ...mainPayload },
    config: {
      headers: getHeaders(fingerprint, userType ?? "", accessToken ?? ""),
    },
  };
  const submitQuoteRequest = baseRequestHandler(({ params, config }) =>
    axios.post(url, params, config)
  );
  return await submitQuoteRequest(payload);
};

export const updateQuoteProperties = async (
  fingerprint,
  userType,
  accessToken,
  quoteID,
  updatedProperties
) => {
  const url = `${PROXY_PATH}/quotes/${quoteID}/update-properties`;
  const payload = {
    params: JSON.stringify({ properties: [...updatedProperties] }),
    config: {
      headers: getHeaders(fingerprint, userType ?? "", accessToken ?? ""),
    },
  };

  const updateQuotePropertiesRequest = baseRequestHandler(
    ({ params, config }) => axios.post(url, params, config)
  );
  return await updateQuotePropertiesRequest(payload);
};

export const policyHolders = async (
  fingerprint,
  userType,
  accessToken,
  quoteID,
  policyHolderProperties,
  userData,
  policyHolderID,
  flags
) => {
  const url = `${PROXY_PATH}/versions/${VERSION}/policy-holders`;
  const params = {
    quote_id: quoteID,
    properties: [...policyHolderProperties],
    ...userData,
  };
  if (policyHolderID) params.policyholder_id = policyHolderID;
  if (flags) params.user.flags = flags;

  const payload = {
    params: JSON.stringify(params),
    config: {
      headers: getHeaders(fingerprint, userType ?? "", accessToken ?? ""),
    },
  };
  const policyHoldersRequest = baseRequestHandler(({ params, config }) =>
    axios.post(url, params, config)
  );
  return await policyHoldersRequest(payload);
};

export const updateProtectedAssetProperties = async (
  fingerprint,
  userType,
  accessToken,
  protectedAssetID,
  protectedAssetProperties
) => {
  const url = `${PROXY_PATH}/protected-assets/${protectedAssetID}/update-properties`;
  const payload = {
    params: {
      properties: [...protectedAssetProperties],
    },
    config: {
      headers: getHeaders(fingerprint, userType ?? "", accessToken ?? ""),
    },
  };
  const updatePropertiesRequest = baseRequestHandler(({ params, config }) =>
    axios.post(url, params, config)
  );
  return await updatePropertiesRequest(payload);
};

// CUSTOM ACTIONS

export const retrieveRateBaseTable = async (
  fingerprint,
  userType,
  accessToken
) => {
  const url = `${PROXY_PATH}/custom-actions/retrieve-rate-base-table/execute`;
  const payload = {
    params: { data: { version_id: VERSION } },
    config: {
      headers: getHeaders(fingerprint, userType ?? "", accessToken ?? ""),
    },
  };
  const rateBaseDataRequest = baseRequestHandler(({ params, config }) =>
    axios.post(url, params, config)
  );
  return await rateBaseDataRequest(payload);
};

export const getDocsSign = async (
  fingerprint,
  userType,
  accessToken,
  quoteID
) => {
  const url = `${PROXY_PATH}/custom-actions/get-docs-sign/execute`;
  const payload = {
    params: { data: { quote_id: quoteID } },
    config: {
      headers: getHeaders(fingerprint, userType ?? "", accessToken ?? ""),
    },
  };
  const getDocsSignRequest = baseRequestHandler(({ params, config }) =>
    axios.post(url, params, config)
  );
  return await getDocsSignRequest(payload);
};

export const requestSMSCode = async (
  fingerprint,
  userType,
  accessToken,
  quoteID
) => {
  const url = `${PROXY_PATH}/custom-actions/request-sms-code/execute`;
  const payload = {
    params: JSON.stringify({ data: { quote_id: quoteID } }),
    config: {
      headers: getHeaders(fingerprint, userType ?? "", accessToken ?? ""),
    },
  };
  const getSMSRequest = baseRequestHandler(({ params, config }) =>
    axios.post(url, params, config)
  );
  return await getSMSRequest(payload);
};

//CDN

export const getLocationMap = async () => {
  const url = `${CDN_BASE_URL}/insurance-products/whitelabel-bios/config-files/paymentprotection-fe/domains_list_paymentprotection.json`;
  const getMapping = baseRequestHandler(() => axios.get(url));
  return await getMapping();
};

export const fetchTheme = async () => {
  const addressResponse = await getLocationMap();
  if (addressResponse.type === "error") throw addressResponse.error;
  const currentLocation = window.location.hostname;
  const url = `${CDN_BASE_URL}${
    addressResponse.data?.[currentLocation] ?? addressResponse.data?.default
  }`;
  const getThemeAndConfig = baseRequestHandler(() => axios.get(url));
  return await getThemeAndConfig();
};
