import { PROXY_PATH, SECONDARY_COLOR } from "../../config";
import { getItemLocalStorage } from "../../utils/helper";
import { Row, Col, Nav } from "react-bootstrap";
import { UIactions } from "../../actions/index";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import { ThreeDots } from "react-loader-spinner";
import axios from "axios";
import RightSidebar from "./RightSidebar";
import { getDataByNamespace } from "./utils";
import { LOCAL_STORAGE_KEYS as LOCAL_STORAGE } from "../../utils/constants";
import { SmartlinkComponent } from "@habit.analytics/habit-smartlink-reactcomponent";

const Payment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const quoteProperties = useSelector((state) => state.quote.properties);

  const quoteId = useSelector((state) => state.quote.id);

  const insuranceProduct = useSelector((state) => state.insuranceProduct);
  const customData = useSelector((state) => state.customData);
  const user = useSelector((state) => state.user);
  const userDoesntHaveID =
    user.id === null || user.id === undefined || user.id === "";
  const smartlinkHash = useSelector((state) => state.UI.flow.smartlink_hash);

  const [quote_id, setQuoteid] = useState("");
  const [isLoader, setisLoader] = useState(false);
  const [globalDisclaimer, setGlobalDisclaimer] = useState([]);
  const [productDisclaimer, setProductDisclaimer] = useState("");
  const [payment_frequency_name, setPaymentFrequencyName] = useState("");
  const [blockEdit, setBlockEdit] = useState(false);

  const usertype = getItemLocalStorage(LOCAL_STORAGE.TYPE);
  const fingerprint = getItemLocalStorage(LOCAL_STORAGE.FINGERPRINT);
  const access_token = getItemLocalStorage(LOCAL_STORAGE.ACCESS_TOKEN);

  const isSelfPurchaseFlow = usertype === "user" || usertype === "guest";

  const sidebarProps = {
    usertype: usertype,
    payment_frequency_name: payment_frequency_name,
    rate_base: getDataByNamespace("rate_base", quoteProperties),
    productname: insuranceProduct.name,
    documents: insuranceProduct.documents.at_offer,
    policyHolderName: customData.policyholder_name,
  };

  var headers;
  headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "X-FINGERPRINT": fingerprint,
  };

  if (usertype === "guest") {
    headers = {
      "Content-Type": "application/json",
      "X-FINGERPRINT": fingerprint,
    };
  }

  useEffect(() => {
    const paymentFrequencyLabel = customData.payment_frequency.find(
      (property) =>
        property.data ===
        quoteProperties.find(
          (property) => property.namespace === "payment_frequency"
        ).data
    ).label;

    setPaymentFrequencyName(paymentFrequencyLabel);

    setisLoader(true);
    setTimeout(function () {
      setisLoader(false);
    }, 1500);

    setGlobalDisclaimer(
      insuranceProduct.legal_disclaimers.find((x) => x.type === "global")
    );
    setProductDisclaimer(
      insuranceProduct.legal_disclaimers.find((x) => x.type === "by_product")
    );

    setQuoteid(quoteId);
  }, [
    customData.payment_frequency,
    insuranceProduct.legal_disclaimers,
    quoteId,
    quoteProperties,
    smartlinkHash,
  ]);

  const submitContract = async () => {
    const payload = {};
    if (userDoesntHaveID) payload.user = { name: user.name, email: user.email };
    else payload.user_id = user.id;

    var formData = JSON.stringify(payload);
    const response = await axios.post(
      `${PROXY_PATH}/quotes/${quote_id}/submit-contract`,
      formData,
      {
        headers: headers,
      }
    );
    const firstPayment = response?.data?.customized?.payments[0];
    const success = Boolean(firstPayment?.id);
    setBlockEdit((previous) => previous || success); //once is blocked, it can never be unblocked somewhere
    return { success, payment_id: firstPayment?.id ?? "" };
  };

  const onPaymentSuccess = (paymentData = {}) => {
    if (paymentData && paymentData !== "undefined") {
      const paymentDataJson = paymentData ? JSON.parse(paymentData) : {};
      const mbDataKeys = [
        "mb_entity",
        "mb_reference",
        "mb_amount",
        "mb_validity",
      ];
      const hasAllKeys = mbDataKeys.every((key) =>
        paymentDataJson.hasOwnProperty(key)
      );
      if (hasAllKeys) {
        const treatedData = JSON.stringify({
          entity: paymentDataJson.mb_entity,
          reference: paymentDataJson.mb_reference,
          amount: paymentDataJson.mb_amount,
          last_updated: paymentDataJson?.mb_validity ?? "",
        });
        dispatch(
          UIactions.definePaymentData({ method: "mb", data: treatedData })
        );
      }
    }
    setisLoader(false);
    navigate("/success");
  };

  return (
    <Fragment>
      <ToastContainer theme="dark" autoClose={10000} />
      {isLoader === true && (
        <ThreeDots
          height="90"
          width="90"
          radius="9"
          color="#FDBE14"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass="ajax-loader"
          visible={true}
        />
      )}
      <Row className="text-start m-0 simulation-div align-items-start">
        <Col md={9} className="leftbar">
          {window.innerWidth < 768 && (
            <>
              <h3
                className="left-content-heading text-center d-block mt-2 mb-4"
                style={{ color: SECONDARY_COLOR }}
              >
                Pagamento
              </h3>
              <Nav
                as="ul"
                className="mobile-bar step-four align-items-center justify-content-between"
              >
                <Nav.Item as="li" className="active"></Nav.Item>
                <Nav.Item as="li" className="active"></Nav.Item>
                <Nav.Item as="li" className="active"></Nav.Item>
                <Nav.Item as="li" className="active"></Nav.Item>
              </Nav>
            </>
          )}
          <div className="left-content-box active">
            <h3
              className="left-content-heading m-0"
              style={{ color: SECONDARY_COLOR }}
            >
              <i className="bi bi-check-circle-fill"></i> Simulação
            </h3>
            {blockEdit === false && (
              <a
                type="button"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(UIactions.setStep(0));
                }}
                className="edit_btn"
              >
                <i className="bi bi-pencil"></i>Editar
              </a>
            )}
          </div>
          <div className="left-content-box active">
            <h3
              className="left-content-heading m-0"
              style={{ color: SECONDARY_COLOR }}
            >
              <i className="bi bi-check-circle-fill"></i> Dados de{" "}
              {usertype === "lead" ? "Validação" : "Gerais"}
            </h3>
            {blockEdit === false && (
              <a
                type="button"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(UIactions.setStep(1));
                }}
                className="edit_btn"
              >
                <i className="bi bi-pencil"></i>Editar
              </a>
            )}
          </div>
          <div className="left-content-box active">
            <h3
              className="left-content-heading m-0"
              style={{ color: SECONDARY_COLOR }}
            >
              <i className="bi bi-check-circle-fill"></i> Dados do Beneficiário
            </h3>
            {blockEdit === false && (
              <a
                type="button"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(UIactions.previousStep());
                }}
                className="edit_btn"
              >
                <i className="bi bi-pencil"></i>Editar
              </a>
            )}
          </div>
          <div className="left-content-box">
            <h3
              className="left-content-heading"
              style={{ color: SECONDARY_COLOR }}
            >
              <span
                className="counter-box d-inline-flex align-items-center justify-content-center"
                style={{ color: SECONDARY_COLOR, borderColor: SECONDARY_COLOR }}
              >
                4
              </span>{" "}
              Método de Pagamento
            </h3>
            {!isSelfPurchaseFlow && (
              <div className="d-flex flex-column align-items-center justify-content-center">
                <img
                  style={{
                    marginBottom: "3em",
                    height: "60px",
                    width: "60px",
                  }}
                  src={require("../../assets/images/email.png")}
                  alt=""
                />
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "19px",
                    fontWeight: 600,
                    lineHeight: "28.5px",
                    letterSpacing: "0.03em",
                    textAlign: "center",
                    marginBottom: "3em",
                    color: "#00133E",
                  }}
                >
                  Obrigado por escolher o nosso seguro.
                </p>
                <p
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "23px",
                    letterSpacing: "0.03em",
                    textAlign: "center",
                    padding: "0 3em",
                    marginBottom: "5em",
                    color: "#7F889E",
                  }}
                >
                  Ao confirmar, será enviado um email com link para que o
                  cliente efectue o pagamento.
                </p>

                <button
                  type="button"
                  onClick={async () => {
                    const { success } = await submitContract();
                    if (success) navigate("/success");
                  }}
                  className="submit-button able"
                >
                  CONFIRMAR ADESÃO
                </button>
              </div>
            )}
            {isSelfPurchaseFlow && (
              <SmartlinkComponent
                hash={smartlinkHash}
                prePaymentMethod={submitContract}
                onPaymentSuccess={onPaymentSuccess}
              />
            )}
          </div>

          <div className="d-block footer-div">
            <div className="footer-text">
              {globalDisclaimer ? (
                <p className="m-0">{globalDisclaimer.text}</p>
              ) : (
                ""
              )}
              {productDisclaimer ? (
                <p className="m-0">{productDisclaimer.text}</p>
              ) : (
                ""
              )}
            </div>
          </div>
        </Col>
        <Col md={3} className="right-sidebar">
          <RightSidebar
            props={sidebarProps}
            previousFunction={
              blockEdit ? null : () => dispatch(UIactions.previousStep())
            }
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default Payment;
