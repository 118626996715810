export const PROXY_PATH="https://api.distributors.integrations.habit.io/1-habit-invoice-sb-4a1a116a"
export const VERSION="a5f7ba8d-5cd9-4665-8613-0084eaec088d"

export const INTRO='no'
export const OPTIONAL_COV='no'
export const PC_NAMES='no'
export const MAIN_COLOR="#FDBE14"
export const SECONDARY_COLOR="#001C7B"
export const SALES_CHANNEL="sellers_portal"
export const ANALYTICS_KEY="None"

export const PARTNER_NAME="'''no'''"

export const PARTNER_IMAGE="'''no'''"


export const CDN_BASE_URL="https://cdn.integrations.habit.io"

export const WHITELABEL_CONFIG_URL="https://cdn.integrations.habit.io/insurance-products/whitelabel-bios/config-files/custom-domains"
export const PUBLIC_URL="https://4a1a116a.paymentprotection.product.integrations.habit.io/"



export const API_URL="https://platform.integrations.habit.io"

export const BASENAME="1-habit-invoice-sb-4a1a116a"
export const PAYMENT="false"
export const HABIT_TAG="yes"
